<template>
	<div class="marketing-section">
		<div class="page--service container container-l mx-auto">
			<section>
				<hero :title="service.title" :text="service.discription">
					<img class="mx-auto" :src="service.main_media" alt="" />
				</hero>
			</section>
			<section v-if="service.tecnologies.length" class="tecnology-section">
				<h2 class="title text-2xl sm:text-4xl xl:text-6xl">
					{{ $t("headings.services") }}
				</h2>
				<div class="marketing-tecnologies">
					<tecnologies :tecnologies="service.tecnologies" />
				</div>
			</section>
			<div class="flex justify-center">
				<button class="btn is-main nav-btn">
					<router-link to="/contact-us">
						Get Quotation Now
					</router-link>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
import Tecnologies from "../components/Tecnologies.vue";
import Hero from "../components/Hero.vue";

export default {
	components: {
		Tecnologies,
		Hero
	},

	data() {
		return {
			service: {
				title: "Marketing",
				discription:
					"We have a talented marketing and sales team that can accurately achieve your goals.",
				main_media: "images/Marketing.svg",
				tecnologies: [
					// { id: 3, image: "images/Html 5.png", name: "Marketing" },
					{ id: 4, image: "images/Branding.png", name: "Branding" },
					{
						id: 5,
						image: "images/Content writing.png",
						name: "Content writing"
					},
					{ id: 6, image: "images/Seo.png", name: "Seo" },
					{
						id: 7,
						image: "images/Email marketing.png",
						name: "Email marketing"
					},
					{
						id: 8,
						image: "images/Social media marketing.png",
						name: "Social media marketing"
					}
					// { id: 9, image: "images/PHP Laravel.png", name: "PHP Laravel" },
					// { id: 10, image: "images/Vue js.png", name: "Vue js" },
					// { id: 11, image: "images/Vutify.jpg", name: "Vutify" },
					// { id: 12, image: "images/Express js.png", name: "Express js" },
					// { id: 13, image: "images/Django.png", name: "Django" },
					// { id: 14, image: "images/Mysqlpng.png", name: "Mysql" },
					// {
					// 	id: 15,
					// 	image: "images/MongoDB.png",
					// 	name: "MongoDB"
					// },
					// { id: 16, image: "images/Apache", name: "Apache" },
					// { id: 17, image: "images/Nginx.png", name: "Nginx" },
					// { id: 18, image: "images/Varnish.png", name: "Varnish" },
					// { id: 19, image: "images/Memcached.jpg", name: "Memcached" },
					// { id: 20, image: "images/Redis.png", name: "Redis" },
					// { id: 21, image: "images/Centos.png", name: "Centos" },
					// { id: 22, image: "images/Ubuntu.jpg", name: "Ubuntu" },
					// { id: 23, image: "images/Cpanel.png", name: "Cpanel" }
				]
			},
			selectedSection: null,
			chunk: [],
			chunks: []
		};
	}
};
</script>
<style lang="scss">
.marketing-section {
	.marketing-tecnologies {
		.tecnology-container {
			.tecnology {
				@apply text-center mt-4 w-1/5;
				figure {
					@apply w-40 h-40;
				}
			}
		}
	}
}
</style>
